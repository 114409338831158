.running {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        // background: -webkit-linear-gradient(var(--color-primary), var(--color-secondary));
        background-color: var(--color-primary);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 6.5vh;
        margin: 5vh;
    }

    .textWPhoto {
        div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            margin-bottom: 5vh;
            margin-top: 1vh;
        }
        display: flex;

        @media screen and (max-width: 1048px) {
            flex-direction: column;
        }

        justify-content: space-between;
        align-items: center;
        text-align: justify;
        width: 80%;

    }

    .offer {
        display: flex;
        flex-direction: column;
        align-items: center;


        img {
            height: 40vh;
            width: auto;
        }

        .minorTitle {
            // background: -webkit-linear-gradient(var(--color-primary), var(--color-secondary));
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 5vh;
            background-color: var(--color-secondary);

        }

        p {
            margin: 1vh 3vh 1vh 3vh;

            @media screen and (min-width: 1048px) {
                margin: 0;
            }

            max-width: 80%;

            font-size: 2.5vh;
            display: flex;
            flex-direction: column;
        }

        span {
            text-align: center;
        }

    }
}