.split {
    border-top: 2px solid #205DAD;
    margin: 1vh 0 1vh 0;
    width: 100%;
    height: 1px;
}

.nameSplit {
    border-top: 1px solid #205DAD;
    border-bottom: 1px solid #205DAD;
    margin: 1vh 0 1vh 0;
    width: 100%;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
}