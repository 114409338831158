.contact {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title {
        background-color: var(--color-primary);
        background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 4.5vh;
        margin: 1vh;
    }
    .short {
        margin-top: 5vh !important;
        font-size: 3vh;
        font-weight: 600;
        text-align: center ;
        width: 75%;
    }


    p {
        margin: 1vh 3vh 1vh 3vh;
        
        @media screen and (min-width: 1048px) {
            margin: 0;
            width: 50%;
        }

        text-align: justify;
        font-size: 2vh;

    }

    div {
        width: 100%;
    }

    .form {

        @media screen and (min-width: 1048px) {
            margin: 10vh;
        }

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        div {

            @media screen and (min-width: 1048px) {
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                justify-content: center;
                width: calc(40%);
                label {
                    margin-top: 3vh;
                    display: flex;
                    justify-content: space-between;
                }
            }
            
            @media screen and (max-width: 1048px) {
                display: flex;

                flex-direction: column;
                align-items: center;
                width: 100%;

                label {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                }
            }

        }

        width: calc(100% - (2*10vh));

        label input {
            height: 3vh;
            font-size: 2.5vh;
            border: 2px solid black;
        }
        label {
            font-size: 3vh
        };



        [aria-disabled="true"] {
            border: 2px solid red;
        }

        textarea {

            @media screen and (min-width: 1048px) {
                width: 75%;
            }
            
            margin-top: 5vh;
            resize: none;
            width: 100%;
            height: 10vh;
        }

        button {
            border: none;
            background-color: var(--color-primary);
            width: fit-content;
            width: fit-content;
            padding: 1vh;
            margin-top: 5vh;
            display: flex;
            justify-content: center;
            align-items: center;
            color: white;
            font-size: 2vh;
            height: 6vh;
            width: 10vh;
        }

        button:disabled {
            background-color: gray;
        }
    }

}