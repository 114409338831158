:root {
    --margins-shortAbout: 11vw;
    --sizes-shortAbout: 50vh;
}

.home {
    display: flex;
    flex-direction: column;
    align-items: center;

    .shortAbout {
        display: flex;
        flex-direction: column;

        @media screen and (min-width: 1048px) {
            flex-direction: row;
        }

        margin: 5vh;
        margin-left: var(--margins-shortAbout);
        margin-right: var(--margins-shortAbout);
        align-items: center;
        width: calc(100% - 2*var(--margins-shortAbout));
        justify-content: space-between;

        img {
            width: var(--sizes-shortAbout);
            height: var(--sizes-shortAbout);
            border-radius: 50%;
        }
        
        .right{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }

        .text {

            h2,
            p {
                margin: 1vh 3vh 1vh 3vh;

                @media screen and (max-width: 1048px) {
                    margin: 0;
                }

                text-align: justify;
                font-weight: 400;
                span {
                    font-weight: 600;
                }
            }

            h2 {
                // background: -webkit-linear-gradient(var(--color-primary), var(--color-secondary));
                // background-clip: text;
                // -webkit-text-fill-color: transparent;
                font-size: 2vh;
            }

            p {
                
                font-size: 2vh;
            }

            @media screen and (min-width: 1048px) {
                width: 80%;
            }
            display: flex;
            flex-direction: column;
            align-items: left !important;
            justify-content: left !important;
        }
    }

    .contact {
        background-color: var(--color-secondary);
        width: fit-content;
        width: fit-content;
        padding: 1vh;
        height: 2vh;
        margin-bottom: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;
        a {
            margin: 0;
            font-size: 3vh;
            display: flex;
            text-align: center;
            justify-content: center;
            text-decoration: none;
            color: white;
        }
        transform: translateY(8vh);
    }
}