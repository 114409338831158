.teraphy {
    display: flex;
    flex-direction: column;
    align-items: center;

    h1,
    h2 {
        color: var(--color-primary);
        margin: 1vh;
    }

    h1 {
        font-size: 6.5vh;
    }

    h2 {
        font-size: 5vh;
    }

    ul {
        list-style: none;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        li {
            margin-left: -2vh !important;
            margin-top: 1vh !important;
        }

        @media screen and (max-width: 1024px) {
            margin: 0 !important;

            li {
                margin-top: 1vh;
                display: flex;
                text-align: left;
            }

            display: flex;
            flex-direction: column;
            justify-content: left;
        }

        font-size: 3vh;
        font-weight: 600;
        text-align: center;
    }

    .offer {
        margin: 5vh!important;
    }

    .intro {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        p {
            margin: 5vh;
            font-size: 2.5vh;
            width: 80%;
            text-align: center;
        }

        h1 {
            font-size: 4.5vh;
            color: var(--color-secondary);
            margin-top: 5vh;
            margin-bottom: 5vh;
            background-color: black;
            width: 100%;
            text-align: center;
            padding: 1vh 0 1vh 0;
        }

    }

    .contact {
        background-color: var(--color-primary);
        width: fit-content;
        width: fit-content;
        padding: 1vh;
        height: 6vh;
        margin-top: 5vh;
        display: flex;
        justify-content: center;
        align-items: center;

        a {
            margin: 0;
            font-size: 4vh;
            display: flex;
            text-align: center;
            justify-content: center;
            text-decoration: none;
            color: white;
        }
        margin-bottom: 10vh;
    }
}