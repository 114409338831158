:root {
    --about-margins: 10vw;
}

.about {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .aboutText {
        .title {
            
            background-clip: text;
            -webkit-text-fill-color: transparent;
            font-size: 6vh;
            background-color: var(--color-primary);
        }

        margin-left: var(--about-margins);
        margin-right: var(--about-margins);
    }
    p {
        font-size: 2vh;
        br {
            height: 0.5vh;
        }
    }
    .aboutImg {
        @media screen and (max-width: 1024px) {
            flex-direction: column;
        }
        margin-left: var(--about-margins);
        margin-right: var(--about-margins);
        display: flex;
        flex-wrap: nowrap;

        img {
            width: 35vh;
            height: 35vh;
            object-fit: cover;
            margin: 2vh;
            box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        }

        img:hover {

            animation-name: example;
            animation-duration: 2s;
            width: 40vh;
            height: 40vh;
            margin: -0.5vh;
            box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8), 0 12px 40px 0 rgba(0, 0, 0, 0.50);
        }
    }

    .segment {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 80vw;

        ul {
            list-style: none;

            @media screen and (min-width: 1024px) {
                columns: 1;
            }
        }

        li {
            list-style: none;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin: 0 0 1vh 0;
        }

        FontAwesomeIcon {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        p {
            margin: 0 1vw 0 1vw;
        }
    }
}

@keyframes example {
    from {
        width: 35vh;
        height: 35vh;
        margin: 2vh;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.8), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    to { 
        width: 40vh;
        height: 40vh;
        margin: -0.5vh;
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.8), 0 12px 40px 0 rgba(0, 0, 0, 0.50);
    }
}