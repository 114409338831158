.topbar {
    border-top: 2px solid #205DAD;
    border-bottom: 2px solid #205DAD;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    height: fit-content;

    @media screen and (max-width: 1024px) {
        justify-content: center;
    }

    @media screen and (min-width: 1024px) {
        justify-content: space-evenly;
    }

    a {
        @media screen and (max-width: 1024px) {
            padding: 0.75vh 1vh 0.75vh 1vh;
        }

        @media screen and (min-width: 1024px) {
            padding: 2vh;
        }

        font-size: 2.5vh;
        font-weight: 600;
        color: black;
        text-decoration: none;
    }

    a:hover {
        animation: a_blanking 0.5s;
        color: #fe7ab9;
    }
}

.imgTopbar {
    margin-left: 25vw;
    margin-right: 25vw;
    width: calc(100% - 50vw);
    height: auto;
}

@keyframes a_blanking {
    from {
        color: black;
    }

    to {
        color: #fe7ab9;
    }
}