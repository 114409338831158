.media {
    display: flex;
    justify-content: center;
    align-items: center;
    @media screen and (max-width: 1024px) {
        flex-wrap: wrap;
    }
    img {
        @media screen and (min-width: 1024px) {   
            margin-left: 10vh;
            margin-right: 10vh;
        }
        width: 20vh;
        height: auto;
    }
}